<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Contact Relation Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="ContactRelationDetailForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="contactRelation.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="contactRelation.remark"
            name="Remark"
            label="Remark"
            :rules="{ required: false }"
            trim />
          <StatusRadioButton :status.sync="contactRelation.status" />
          <b-row>
            <b-col>
              Created At : {{ contactRelation.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ contactRelation.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ contactRelation.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ contactRelation.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              :disabled="!compareOldValue"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialContactRelation: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    contactRelation: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.contactRelation) === JSON.stringify(this.initialContactRelation)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.contactRelation.id,
        name: this.contactRelation.name,
        remark: this.contactRelation.remark,
        status: this.contactRelation.status
      }
      this.$emit('edit-contact-relation', payload)
    },
    resetForm () {
      this.contactRelation = { ...this.initialContactRelation }
      this.$refs.ContactRelationDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.contactRelation.id,
        Name: this.contactRelation.name
      })
    }
  }
}
</script>

<style>

</style>
