import HttpRequest from './HttpRequest'

class ContactRelationProvider extends HttpRequest {
  findAll () {
    return this.get('/contact-relation')
  }

  findOne (id) {
    return this.get(`/contact-relation/${id}`)
  }

  create (payload) {
    return this.post('/contact-relation', payload)
  }

  update (id, payload) {
    return this.patch(`/contact-relation/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/contact-relation/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/contact-relation?page=${page}&limit=${limit}`, query)
  }
}

export default ContactRelationProvider
